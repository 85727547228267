<template>
  <div>
    <div class="main-panel">
      <div class="content">
        <div class="text-center">
          <h1 class="title">Best Dad Jokes A/B Tested</h1>
          <h5 class="subTitle">
            Results Page
          </h5>
        </div>






        <b-row class="single">
          <b-col md="9">
            <h3>The Best 10</h3>
            <ul class="list-unstyled">
              <li v-for="c in top" :key="c.joke">
                <p>{{ c.joke }} </p>
                <div class="explain-ratio">This joke was selected once out of every {{(1/c.ratio).toFixed(2).toString()}} times it was shown</div>
              </li>
            </ul>
          </b-col>
        </b-row>
        <p class="credtis">
          <router-link v-bind:to="'Credits'">Credits</router-link>
        </p>
        <appFooter></appFooter>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import appFooter from "./appFooter";

import baseURL from '../utils';

export default {
  name: "Main",
  components: {
    appFooter,
  },
  data() {
    return {
      top: [],
      bottom: [],
      ballot: {},
      hasVoted: false,
    };
  },

  mounted() {

    axios.get(baseURL + "/voteCountFor").then((response) => {
      const t = Object.values(response.data);
      const t1 = t.filter((j) => j != null && j.ratio != 0);
      t1.sort((a, b) =>
        a.ratio < b.ratio ? 1 : -1
      );
      this.top = t1;
    });

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-panel {
  background-color: white;
  padding: 2.5em;
}

.title {
  font-size: 5em;
  padding: 0;
  line-height: 80%;
  font-weight: 800;
  color: #020202;
}

.subTitle {
  font-size: 2em;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  line-height: 80%;
  font-weight: 700;
  color: #020202;
}

.blurb {
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 4em;
  padding-right: 4em;
  font-weight: 500;
  line-height: 1.5;
  color: #020202;
}



.bParent {
  display: block;
  padding-bottom: 0.2em;
}

.bWages {
  display: block;
  font-size: 0.8em;
}

.voteButtonsRow {
  margin-bottom: 3em;

  padding-bottom: 1em;
  /* background-color: lightgray; */
}

.voteButton {
  font-size: 1.2em;
  margin-top: 2em;
}

.joke {
  background-color: lightgray;

  padding: 1em;
  font-size: 1.3em;
}

.voteAgain {
  font-size: 1.2em;
  cursor: pointer;
  background-color: lightgray;
  padding-top: 2em;
  padding-bottom: 2em;

  margin-top: 2em;
  margin-bottom: 2em;
}

em {
  font-weight: bold;
}

.single {
  padding: 30px 15px;
  margin-top: 40px;
  background: #fcfcfc;
  border: 1px solid #f0f0f0;
}

.single ul {
  margin-bottom: 0;
}

.single li {
  color: #666;
  font-size: 14px;
  border-bottom: 1px solid #f0f0f0;
  line-height: 40px;
  display: block;
  text-decoration: none;
}

.single li:last-child {
  border-bottom: 0;
}

.list-unstyled li {
  margin: 1em;
  padding: 1em;
  line-height: 1;
  font-size: 1.5em;
  box-shadow: rgba(114, 151, 113, 0.2) 0px 7px 29px 0px;
}

.credtis {
  margin: 3em;
}

.explain-ratio {
  color: #8F8F8F;
  font-size: 0.8rem;
  line-height: 1.1;
}
</style>
