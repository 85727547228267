import VueRouter from 'vue-router'
import Main from '@/components/Main'
import Editor from '@/components/Editor'
import Credits from '@/components/Credits'
import Best from '@/components/Best'


export default new VueRouter({
  // mode: 'history',
  routes: [
    {
      path: '/edit/',
      name: 'Editor',
      component: Editor,
      props: true
    },   
    {
      path: '/credits/',
      name: 'Credits',
      component: Credits,
      props: true
    },   
    {
      path: '/best/',
      name: 'Best',
      component: Best,
      props: true
    },   
    {
      path: '/',
      name: 'Main',
      component: Main
    }

  ]
})
