<template>
  <footer class="fixed-bottom section footer " >

            Made by <a href="https://rightclick.com.au/">rightclick.com.au</a>



  </footer>
</template>
<script>
// import { CollapseTransition } from "vue2-transitions";
// import Modal from '@/components/Modal';

export default {
  name: "Footer",
  components: {
    // CollapseTransition
    // Modal
  },
  computed: {
    uid() {
      return this.$store.getters.uid;
    },
    firebaseUser() {
      return this.$store.getters.firebaseUserName;
    },
    loggedIn() {
      var firebaseUser = this.$store.getters.firebaseUser;
      // console.log("firbase User: %O", firebaseUser)
      return !(firebaseUser == null || firebaseUser.isAnonymous);
    },
  },
};
</script>
<style scoped>


.footer {
  padding-top: 1em;
  padding-left: 1em;
  background-color: #bbbbbb;
  width: 100%;
  height: 60px; 

}


</style>
