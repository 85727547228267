<template>
  <div>
    <div class="main-panel">
      <div class="content">
        <div class="text-center">
          <h1 class="title">Edit Page</h1>
        </div>

        <b-form-textarea
          size="lg"
          class="edit-box"
           rows="9"
          v-model="newJoke"
          placeholder="add multiple lines"
        ></b-form-textarea>
        <div>
          <b-button  class="save-button" v-on:click="saveJoke()">Save</b-button>
        </div>

        <appFooter></appFooter>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import appFooter from "./appFooter";

import baseURL from '../utils';


export default {
  name: "Main",
  components: {
    appFooter,
  },
  data() {
    return {
      newJoke: "",
    };
  },
  methods: {
    saveJoke: function () {
      const url = baseURL + `/newJoke?joke=${this.newJoke}`;

      console.log(url);
      axios.get(url).then(() => {
        this.newJoke = "";
      });
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  padding: 1em;
}

.edit-box {
  padding: 9em;
}


.save-button {
  margin: 3em;
  padding-left: 3em;
  padding-right: 3em;
}
</style>
