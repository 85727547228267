<template>
  <div>
    <div class="main-panel">
      <div class="content">
        <div class="text-center">
          <h1 class="title">Credits Page</h1>
        </div>

        <p>
          Inspired by
          <a href="https://www.youtube.com/watch?v=ALy6e7GbDRQ"
            >What Is The Best Thing?</a
          >
          and my Kids.
        </p>
        <p>Jokes were gathered from around the web Including.</p>
        <ul>
          <li>
            <a href="https://dadabase.co/blog/the-best-places-to-find-dad-jokes-online/">Dadabase - The Best Places To Find Dad Jokes Online.</a>
          </li>          
          <li>
            <a href="https://twitter.com/Dadsaysjokes">@Dadsaysjokes</a>
          </li>
          <li> <a href="https://www.southernliving.com/culture/virgin-river-season-4-filming-instagram">45 Dad Jokes That Are Actually Funny</a></li>
        </ul>
        <appFooter></appFooter>
      </div>
    </div>
  </div>
</template>

<script>
import appFooter from "./appFooter";

export default {
  components: {
    appFooter,
  },
};
</script>


<style scoped>
.main-panel {
  background-color: white;
  padding: 2.5em;
}

.content {
  padding: 1em;
}
</style>
