<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

// this needs to be copied to C:\work\devvue\questionscount2\questionscount2Client\public\index.html
export default {
  name: "App",
  metaInfo: {
    title: "Dad Joke A/B testing. Vote for your favorite.",
    
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      { name: "description", content: "Searching for the world's best dad jokes.", },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name:"title" ,content:"Dad Joke A/B testing. Vote for your favorite"},
      { name:"description" ,content:"Searching for the world's best dad jokes."},

      { property:"og:type" ,content:"website"},
      { property:"og:url" ,content:"https://rightclick.com.au/groan/"},
      { property:"og:title" ,content:"Dad Joke A/B testing. Vote for your favorite"},
      { property:"og:description" ,content:"Searching for the world's best dad jokes."},
      { property:"og:image" ,content:"https://rightclick.com.au/groan/social.png"},


      { name:"twitter:card" ,content:"summary_large_image"},
      { property:"twitter:url" ,content:"https://rightclick.com.au/groan/"},
      { name:"twitter:title" ,content:"Dad Joke A/B testing. Vote for your favorite"},
      { name:"twitter:description" ,content:"Searching for the world's best dad jokes."},
      { name:"twitter:image" ,content:"https://rightclick.com.au/groan/social.png"}
    ]
  }
};
</script>

<style>
body {
  background-color: gray;
}
</style>




