<template>
  <div>
    <div class="main-panel">
      <div class="content">
        <div class="text-center">
          <h1 class="title">Dad Jokes A/B Tested</h1>
          <h5 class="subTitle">
            Because kids need to learn humor from experts
          </h5>
        </div>

        <b-row>
          <b-col offset="1" cols="10" class="blurb">A/B testing eliminates all the guesswork out of choosing the "best"
            dad joke. We pit jokes head to head to find the "best" joke backed by the "science" of letting users on the
            Internet choose.
          </b-col>

          <b-col offset="1" cols="10" class="blurb">A dad joke should be a wholesome joke of the type said to be told by
            fathers with a punchline that is often an obvious or predictable pun or play on words and usually judged to
            be endearingly corny or unfunny. The ideal response to a dad joke is a groan or kids leaving the room.
          </b-col>
        </b-row>

        <div v-show="hasVoted">
          <h2 class="text-center voteAgain" v-on:click="voteAgain()">
            <a class="twitter-share-button"
              href="https://twitter.com/intent/tweet?text=I%20voted%20for%20the%20best%20dad%20joke%20at%20https://rightclick.com.au/groan/"
              data-size="large">
              Share Your Vote</a>
            or Vote Again?
          </h2>
        </div>
        <div v-show="!hasVoted">
          <h5 class="vote-blurb">Choose the better Dad Joke</h5>
          <b-container fluid="md">
            <b-row class="voteButtonsRow">
              <b-col md="6">
                <h5>Joke A</h5>
                <p class="joke">
                  {{ ballot.a.joke }}
                  <b-button block pill v-on:click="vote('a')" variant="primary" class="voteButton">Vote A</b-button>
                </p>
              </b-col>
              <b-col md="6">
                <h5>Joke B</h5>
                <p class="joke">
                  {{ ballot.b.joke }}
                  <b-button block pill v-on:click="vote('b')" variant="primary" class="voteButton">Vote B</b-button>
                </p>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <h5>Have a Better Joke?</h5>
        <p>
          Email me (I'm a human) at brade@rightclick.com.au with you joke and I'll add it
          to the list. If you include the source I'll include that in the
          <router-link v-bind:to="'Credits'">credits</router-link>.
        </p>
        <h5>Results</h5>
        <p>
          Based on results to-date these are the jokes as voted by previous
          Internet users to be the best.
        </p>
        <b-row class="single">
          <b-col md="6">
            <h3>The Best</h3>
            <ul class="list-unstyled">
              <li v-for="c in top" :key="c.joke">
                <p>{{ c.joke }} </p>
                <div class="explain-ratio">This joke was selected once out of every
                  {{ (1 / c.ratio).toFixed(2).toString() }} times it was shown</div>
              </li>
            </ul>
            <router-link class="best" v-bind:to="'Best'">See Full List</router-link>

          </b-col>
          <b-col md="6">
            <h3>The Worst Joke</h3>
            <ul class="list-unstyled">
              <li v-for="c in bottom" :key="c.joke">
                <p>{{ c.joke }} </p>
                <div class="explain-ratio">This joke was selected once out of every
                  {{ (1 / c.ratio).toFixed(2).toString() }} times it was shown</div>
              </li>
            </ul>
          </b-col>
        </b-row>
        <p class="credits">
          <router-link v-bind:to="'Credits'">Credits</router-link>
        </p>
        <appFooter></appFooter>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import appFooter from "./appFooter";
import baseURL from '../utils';



export default {
  name: "Main",
  components: {
    appFooter,
  },
  data() {
    return {
      top: [],
      bottom: [],
      ballot: {},
      hasVoted: false,
    };
  },
  methods: {
    vote: function (candidate) {
      const url =
        baseURL +
        `/vote?ballotKey=${this.ballot.ballotKey}&ballotFOB=${this.ballot.ballotFOB}&vote=${candidate}`;

      axios.get(url).then(() => {
        this.hasVoted = true;
      });
    },
    voteAgain: function () {
      this.ballot = {};

      axios.get(baseURL + "/requestBallot").then((response) => {
        this.ballot = response.data;
        this.hasVoted = false;
      });
    },
  },
  mounted() {
    axios
      .get(baseURL + "/requestBallot")
      .then((response) => (this.ballot = response.data));

    axios.get(baseURL + "/voteCountFor").then((response) => {
      const t = Object.values(response.data);
      let t1 = t.filter((j) => j != null && j.ratio != 0);
      t1.sort((a, b) =>
        a.ratio < b.ratio ? 1 : -1
      );
      t1 = t1.slice(0, 1);
      this.top = t1;
    });

    axios.get(baseURL + "/voteCountAgainst").then((response) => {
      const t = Object.values(response.data);
      let t1 = t.filter((j) => j != null && j.ratio != 0);
      t1.sort((a, b) =>
        a.ratio < b.ratio ? -1 : 1
      );
      t1 = t1.slice(0, 1);
      this.bottom = t1;
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-panel {
  background-color: white;
  padding: 2.5em;
}

.title {
  font-size: 5em;
  padding: 0;
  line-height: 80%;
  font-weight: 800;
  color: #020202;
}

.subTitle {
  font-size: 2em;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  line-height: 80%;
  font-weight: 700;
  color: #020202;
}

.blurb {
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 4em;
  padding-right: 4em;
  font-weight: 500;
  line-height: 1.5;
  color: #020202;
}

.vote-blurb {
  padding-top: 1em;
  padding-bottom: 1em;
  font-weight: 700;
  font-size: 2em;
}

.bParent {
  display: block;
  padding-bottom: 0.2em;
}

.bWages {
  display: block;
  font-size: 0.8em;
}

.voteButtonsRow {
  margin-bottom: 3em;

  padding-bottom: 1em;
  /* background-color: lightgray; */
}

.voteButton {
  font-size: 1.2em;
  margin-top: 2em;
}

.joke {
  background-color: lightgray;

  padding: 1em;
  font-size: 1.3em;
}

.voteAgain {
  font-size: 1.2em;
  cursor: pointer;
  background-color: lightgray;
  padding-top: 2em;
  padding-bottom: 2em;

  margin-top: 2em;
  margin-bottom: 2em;
}

em {
  font-weight: bold;
}

.single {
  padding: 30px 15px;
  margin-top: 40px;
  background: #fcfcfc;
  border: 1px solid #f0f0f0;
}

.single ul {
  margin-bottom: 0;
}

.single li {
  color: #666;
  font-size: 14px;
  border-bottom: 1px solid #f0f0f0;
  line-height: 40px;
  display: block;
  text-decoration: none;
}

.single li:last-child {
  border-bottom: 0;
}

.list-unstyled li {
  margin: 1em;
  padding: 1em;
  line-height: 1;
  font-size: 1.5em;
  box-shadow: rgba(114, 151, 113, 0.2) 0px 7px 29px 0px;
}

.credits {
  margin: 3em;
}

.best {
  margin: 3em;
  font-size: 1.5em;
}


.explain-ratio {
  color: #8F8F8F;
  font-size: 0.8rem;
  line-height: 1.1;
}
</style>
